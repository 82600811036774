import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { mediaQueries } from '../theme'

import UiContainer from '../components/shared/container'
import Layout from '../components/home/layout'
import PageMetadata from '../components/page-metadata'
import FooterCta from '../components/footer-cta'
import Share from '../components/home/share'
import Templates from '../components/home/templates'
import { withAttrs } from '../utils'
import CustomerCare from '../components/home/customer'
import Creators from '../components/home/creators'
import BaseRunningLine from '../components/home/running-line'
import BaseHero from '../components/home/hero'
import BaseLeads from '../components/home/leads'
import BaseForms from '../components/home/forms'
import BaseEmails from '../components/home/emails'
import BaseAutomations from '../components/home/automations'

const sectionIndents = ({ theme }) => css`
  margin-bottom: ${theme.space[24]};

  ${mediaQueries.lg} {
    margin-bottom: ${theme.space[28]};
  }
`

const ContainerXxl = styled(
  withAttrs(UiContainer, { variant: 'containerXxl' }),
)`
  margin-bottom: ${p => p.theme.space[22]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[26]};
  }
`

const ContainerXl = styled(withAttrs(UiContainer, { variant: 'containerXl' }))`
  ${sectionIndents}
`

const Container = styled(UiContainer)`
  padding: 0;
  ${sectionIndents}

  ${mediaQueries.md} {
    padding: 0;
  }

  ${mediaQueries.lg} {
    padding: ${p => `0 ${p.theme.space[10]}`};
  }
`

const Hero = styled(BaseHero)`
  ${sectionIndents}
`

const Leads = styled(BaseLeads)`
  ${sectionIndents}
`

const Forms = styled(BaseForms)`
  ${sectionIndents}
`

const Emails = styled(BaseEmails)`
  ${sectionIndents}
`

const Automations = styled(BaseAutomations)`
  ${sectionIndents}
`

const RunningLine = styled(BaseRunningLine)`
  margin-bottom: ${p => p.theme.space[13]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[28]};
  }
`

const BottomRunningLine = styled(BaseRunningLine)`
  ${sectionIndents}
`

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <h1 className="visually-hidden">
      Getform – The Most Intuitive Online Form Builder
    </h1>
    <PageMetadata
      title="Getform: Collect Leads, Automate Emails, Send Promo Newsletters"
      description="Create online forms to collect leads, tag your contacts, automate email campaigns, and send promo newsletters.  No website is required. Free plan available."
      keywords="online form builder"
    />

    <Hero />

    <Leads items={data.leadsItems.nodes} />

    <Forms data={data.formsItems.nodes[0]} />

    <Emails data={data.emailsItems.nodes[0]} />

    <Automations data={data.automationsItems.nodes[0]} />

    <RunningLine text="Grow your business with email marketing" />

    <Creators items={data.creatorsItems.nodes} />

    <Container>
      <Share items={data.shareItems.nodes} />
    </Container>

    <ContainerXxl>
      <Templates groups={data.templateGroups.nodes} />
    </ContainerXxl>

    <BottomRunningLine
      text={['Capture Leads', 'Automate Emails', 'Boost Revenue']}
    />

    <ContainerXl>
      <CustomerCare images={data.customerImages.nodes} />
    </ContainerXl>

    <FooterCta />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    templateGroups: allHomeTemplatesYaml {
      nodes {
        title
        description
        wid
        name
        images {
          large {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 80
              )
            }
          }
          small {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 80
              )
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 80
              )
            }
          }
        }
      }
    }
    shareItems: allShareYaml {
      nodes {
        name
        wid
        title
        description
        usecaseImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 70
            )
          }
        }
        templateImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 70
            )
          }
        }
      }
    }
    leadsItems: allLeadsYaml {
      nodes {
        title
        description
        imageDesktop {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 70
            )
          }
        }
        imageMobile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 60
            )
          }
        }
      }
    }
    formsItems: allFormsYaml {
      nodes {
        mainImageDesktop {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 70
            )
          }
        }
        mainImageMobile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 60
            )
          }
        }
        cards {
          title
          description
          className
          imageDesktop {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
        }
      }
    }
    emailsItems: allEmailsYaml {
      nodes {
        mainImageDesktop {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 60
            )
          }
        }
        mainImageMobile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [PNG, WEBP, AVIF]
              quality: 60
            )
          }
        }
        cards {
          title
          description
          imageDesktop {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
        }
      }
    }
    automationsItems: allAutomationsYaml {
      nodes {
        features {
          title
          description
          name
        }
        cards {
          title
          description
          imageDesktop {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: [PNG, WEBP, AVIF]
                quality: 60
              )
            }
          }
        }
      }
    }
    creatorsItems: allCreatorsYaml {
      nodes {
        title
        name
        desktopTemplates {
          wid
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
            }
          }
          name
        }
        mobileTemplate {
          wid
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
            }
          }
        }
      }
    }
    customerImages: allFile(
      filter: { relativeDirectory: { eq: "images/home/customer" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
        }
      }
    }
  }
`
